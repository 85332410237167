.emoji-picker-react .emoji-categories button {
    height: 40px;
    width: 20px;
    padding: 5px 0;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 50% 50%;
    cursor: pointer;
    opacity: .5;
    transition: opacity .1s;
}


.emoji-picker-react .emoji-categories {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.emoji-picker-react .emoji-categories.inactive button,
.emoji-picker-react .emoji-categories.inactive button.active,
.emoji-picker-react .emoji-categories.inactive button:hover {
    opacity: .4;
    cursor: default;
}

.emoji-picker-react .emoji-categories button.active {
    opacity: 1;
}

.emoji-picker-react .emoji-categories button:hover {
    opacity: .7;
}
