.emoji-picker-react ul.skin-tones-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    position: absolute;
    top: 13px;
    right: 40px;
}

.emoji-picker-react ul.skin-tones-list li {
    background-color: currentColor;
    position: absolute;
    padding: 0;
    border-radius: 5px;
    overflow: hidden;
    transition: transform .3s ease;
    box-shadow: 0 0 3px currentColor;
}

.emoji-picker-react ul.skin-tones-list label {
    height: 10px;
    width: 10px;
    padding: 0;
    display: block;
    cursor: pointer;
}

.emoji-picker-react ul.skin-tones-list input {
    height: 0;
    width: 0;
    opacity: 0;
    visibility: hidden;
    display: none;
}

.emoji-picker-react ul.skin-tones-list li.tneutral { color: #ffe082; }
.emoji-picker-react ul.skin-tones-list li.t1f3fb  { color: #ffe0b2; }
.emoji-picker-react ul.skin-tones-list li.t1f3fc  { color: #ffccbc; }
.emoji-picker-react ul.skin-tones-list li.t1f3fe  { color: #795548; }
.emoji-picker-react ul.skin-tones-list li.t1f3ff  { color: #5d4037; }
.emoji-picker-react ul.skin-tones-list li.t1f3fd  { color: #ca7e55; }
