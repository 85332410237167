.emoji-picker-react .emoji-group {
    clear: both;
    padding: 0 15px;
    list-style: none;
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.emoji-picker-react .emoji-group:before {
    content: attr(data-name);
    color: #aaa;
    font-size: 14px;
    position: sticky;
    background: rgba(255,255,255,0.95);
    width: 100%;
    z-index: 1;
    top: 0;
    text-transform: uppercase;
    line-height: 45px;
    font-weight: 700;
}

.emoji-picker-react .emoji-group:after {
        content: '';
        flex: 1000;
        order: 99999;
        flex-basis: 25px;
}

.emoji-picker-react .emoji-scroll-wrapper {
    overflow-y: scroll;
    position: relative;
    height: 100%;
    box-sizing: border-box;
}
