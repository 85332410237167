.emoji-picker-react .variations-wrapper {
    position: relative;
}

.emoji-picker-react .variations-list {
    background: #F4F4F4;
    border-bottom: 1px solid #efefef;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    list-style-type: none;
}

.emoji-picker-react .variations-list button {
    height: 35px;
    width: 35px;
    background-size: 20px 20px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: transparent;
    border-radius: 5px;
    transition: background .1s;
}

.emoji-picker-react .variations-list button:hover {
    background-color: #DBDBDB;
}
